import React, { Component } from 'react';
import {Card, CardBody, CardHeader, CardHeaderToolbar, Pagination} from "../../partials/controls";
import {connect} from "react-redux";
import actions from "../../_redux/actions";
import {apiService} from "../../services/api";
import {LogOffButton} from "../../../auth/Auth0";


class EmailImport extends Component {

  initialState = {
    values: {
      email_update_import: null,
      email_update_import_name: ''
    },
    errors: {
      email_update_import: undefined
    },
    loading: false,
    done: false
  }

  constructor(props) {
    super(props);

    this.state = {
      ...this.initialState
    }
  }


  componentDidMount() {
    this.setState({
      ...this.initialState
    })
  }

  onFileChange(file_key, e) {
    this.setState({
      ...this.state,
      values: {
        ...this.state.values,
        [file_key]: e.target.files[0],
        [file_key + '_name']: e.target.files[0].name,
      },
      errors: {
        ...this.state.errors,
        [file_key]: false
      }
    })
  }

  async onSubmit() {
    this.setState({
      ...this.state,
      loading: true
    })

    const newState = {
      ...this.state
    }

    if(this.state.values.email_update_import === null) {
      newState.errors.email_update_import = true
    }

    if(this.state.errors.email_update_import) {
      this.setState({
        ...this.state,
        loading: false,
        done: false
      })
    } else {
      let formData = new FormData();

      formData.append('season_name', this.state.values.season_name)
      formData.append('email_update_import', this.state.values.email_update_import)

      const {response, error} = await apiService.tasks.email_update(formData);

      this.setState({
        ...this.initialState
      })
    }

  }

  render() {

    return <>
      <div id="kt_subheader" className={`subheader py-2 py-lg-4 subheader-solid`}>
        <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
          {/* Info */}
          <div className="d-flex align-items-center flex-wrap mr-2">
            <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Salesforce</h5>
            <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-4 bg-gray-200" />
            <span className="text-muted font-weight-bold mr-4">Forms</span>
            <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-4 bg-gray-200" />
            <span className="text-muted font-weight-bold mr-4">Email Update</span>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <LogOffButton />
        </div>
      </div>
      <div className="d-flex flex-column-fluid">
        <div className="container-fluid">

          <Card className="card card-custom">
            <CardHeader>
              <div className="card-title">
                <h3 className="card-label">
                  PDA Account : Email Update
                </h3>
              </div>
              <CardHeaderToolbar>
              </CardHeaderToolbar>
            </CardHeader>
            <div>
              <CardBody>

                <div className="form-group">
                  <label>Customer Update Report <span className="text-danger">*</span></label>
                  <div></div>
                  <div className="custom-file">
                    <input
                      type="file"
                      className={this.state.errors.email_update_import ? 'custom-file-input is-invalid': 'custom-file-input'}
                      name="email_update_import"
                      onChange={this.onFileChange.bind(this, 'email_update_import')}
                    />
                    <label className="custom-file-label" htmlFor="customFile">{this.state.values.email_update_import ? this.state.values.email_update_import_name : 'Choose file'}</label>
                    {this.state.errors.email_update_import ? <div className="invalid-feedback">This field is mandatory</div> : null}
                  </div>
                </div>

              </CardBody>

              <div className="card-footer">
                {this.state.loading || this.state.done ?
                  <button disabled={true} type="submit" onClick={this.onSubmit.bind(this)} className="btn btn-primary mr-2 disabled spinner spinner-left">Submit</button> :
                  <button type="submit" onClick={this.onSubmit.bind(this)} className="btn btn-primary mr-2">Submit</button>}
              </div>
            </div>
          </Card>

        </div>
      </div>
    </>
  }
}

export default connect(
  state => ({
    ...state.app,
  }),
  actions
)(EmailImport);