import React, { Component } from 'react';
import {Card, CardBody, CardHeader, CardHeaderToolbar} from "../../partials/controls";
import {connect} from "react-redux";
import actions from "../../_redux/actions";
import {apiService} from "../../services/api";
import {LogOffButton} from "../../../auth/Auth0";


class QBClassRefresh extends Component {

  initialState = {
    values: {
      season_name: ''
    },
    errors: {
      season_name: undefined
    },
    loading: false,
    done: false,
    seasons: []
  }

  constructor(props) {
    super(props);

    this.state = {
      ...this.initialState
    }
  }


  async componentDidMount() {
    const {response} = await apiService.salesforce.season.getAll()

    this.setState({
      ...this.initialState,
      seasons: response.data.data,
      values: {
        ...this.state.values,
        season_name: response.data.data[0].Name
      }
    })
  }

  onChange(e) {
    this.setState({
      ...this.state,
      values: {
        ...this.state.values,
        [e.target.name]: e.target.value
      },
      errors: {
        ...this.state.errors,
        [e.target.name]: false
      }
    })
  }

  async onSubmit() {
    this.setState({
      ...this.state,
      loading: true
    })

    const newState = {
      ...this.state
    }

    if(this.state.values.season_name === null) {
      newState.errors.season_name = true
    }

    if(this.state.errors.season_name) {
      this.setState({
        ...this.state,
        loading: false,
        done: false
      })
    } else {
      await apiService.quickbooks.createClasses(this.state.values.season_name).then(() => {
        this.setState({
          ...this.initialState,
          seasons: this.state.seasons
        })
      })
    }

  }

  render() {

    return <>
      <div id="kt_subheader" className={`subheader py-2 py-lg-4 subheader-solid`}>
        <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
          {/* Info */}
          <div className="d-flex align-items-center flex-wrap mr-2">
            <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Quickbooks</h5>
            <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-4 bg-gray-200" />
            <span className="text-muted font-weight-bold mr-4">Création des Classes</span>
          </div>
          <div className="d-flex align-items-center">
            <LogOffButton />
          </div>
        </div>
      </div>
      <div className="d-flex flex-column-fluid">
        <div className="container-fluid">

          <Card className="card card-custom">
            <CardHeader>
              <div className="card-title">
                <h3 className="card-label">
                  Rafraichir les Classes Quickbooks
                </h3>
              </div>
              <CardHeaderToolbar>
              </CardHeaderToolbar>
            </CardHeader>
            <div>
              <CardBody>

                <div className="form-group">
                  <label>Saison <span className="text-danger">*</span></label>
                  <div></div>
                  <div className="custom-file">
                    <select
                      name="season_name"
                      className="form-control"
                      onChange={this.onChange.bind(this)}
                      value={this.state.values.season_name}
                      >
                      {this.state.seasons.map((season, i) => {
                        return <option key={i} value={season.Name}>{season.Name}</option>
                      })}
                    </select>
                    {this.state.errors.season_name ? <div className="invalid-feedback">Ce champ est obligatoire</div> : null}
                  </div>
                </div>

              </CardBody>

              <div className="card-footer">
                {this.state.loading || this.state.done ?
                  <button disabled={true} type="submit" onClick={this.onSubmit.bind(this)} className="btn btn-primary mr-2 disabled spinner spinner-left">Soumettre</button> :
                  <button type="submit" onClick={this.onSubmit.bind(this)} className="btn btn-primary mr-2">Soumettre</button>}
              </div>

            </div>
          </Card>

        </div>
      </div>
    </>
  }
}

export default connect(
  state => ({
    ...state.app,
  }),
  actions
)(QBClassRefresh);