import React, { Component } from 'react';
import {Card, CardBody, CardHeader, CardHeaderToolbar, Pagination} from "../../partials/controls";
import {connect} from "react-redux";
import actions from "../../_redux/actions";
import {apiService} from "../../services/api";
import {LogOffButton} from "../../../auth/Auth0";
import Select from "react-select";


class ParticipationImport extends Component {

  initialState = {
    values: {
      participant_csv_file: null,
      participant_csv_file_name: '',
      event_id: '',
    },
    errors: {
      participant_csv_file: undefined
    },
    events: [],
    loading: false,
    done: false
  }

  constructor(props) {
    super(props);

    this.state = {
      ...this.initialState
    }
  }


  async componentDidMount() {
    const {response} = await apiService.salesforce.events.getAll()

    this.setState({
      ...this.initialState,
      events: response.data.data,
      values: {
        ...this.state.values,
        event_id: response.data.data[0].Id
      }
    })
  }

  onFileChange(file_key, e) {
    this.setState({
      ...this.state,
      values: {
        ...this.state.values,
        [file_key]: e.target.files[0],
        [file_key + '_name']: e.target.files[0].name,
      },
      errors: {
        ...this.state.errors,
        [file_key]: false
      }
    })
  }

  onSmartChange(e) {
    this.setState({
      ...this.state,
      values: {
        ...this.state.values,
        event_id: e
      }
    })
  }

  async onSubmit() {
    this.setState({
      ...this.state,
      loading: true
    })

    const newState = {
      ...this.state
    }

    if(this.state.values.participant_csv_file === null) {
      newState.errors.participant_csv_file = true
    }

    if(this.state.errors.participant_csv_file) {
      this.setState({
        ...this.state,
        loading: false,
        done: false
      })
    } else {
      let formData = new FormData();

      formData.append('event_id', this.state.values.event_id.value)
      formData.append('participant_csv_file', this.state.values.participant_csv_file)

      const {response, error} = await apiService.tasks.participant_import(formData);

      this.setState({
        ...this.initialState,
        events: this.state.events
      })
    }

  }

  render() {

    let participantOptions = []

    this.state.events.forEach((event, k) => {
      participantOptions.push({
        label: event.Name,
        value: event.Id
      })
    })

    return <>
      <div id="kt_subheader" className={`subheader py-2 py-lg-4 subheader-solid`}>
        <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
          {/* Info */}
          <div className="d-flex align-items-center flex-wrap mr-2">
            <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Salesforce</h5>
            <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-4 bg-gray-200" />
            <span className="text-muted font-weight-bold mr-4">Forms</span>
            <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-4 bg-gray-200" />
            <span className="text-muted font-weight-bold mr-4">Import de Participations</span>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <LogOffButton />
        </div>
      </div>
      <div className="d-flex flex-column-fluid">
        <div className="container-fluid">

          <Card className="card card-custom">
            <CardHeader>
              <div className="card-title">
                <h3 className="card-label">
                  Import de Participations
                </h3>
              </div>
              <CardHeaderToolbar>
              </CardHeaderToolbar>
            </CardHeader>
            <div>
              <CardBody>

                <div className="form-group">
                  <label>Évènement <span className="text-danger">*</span></label>
                  <div></div>
                  <div className="custom-file">
                    <Select
                      options={participantOptions}
                      value={this.state.values.event_id}
                      onChange={this.onSmartChange.bind(this)}
                      name="event_id"
                    />
                    {this.state.errors.season_name ? <div className="invalid-feedback">Ce champ est obligatoire</div> : null}
                  </div>
                </div>

                <div className="form-group">
                  <label>Participant CSV File <span className="text-danger">*</span></label>
                  <div></div>
                  <div className="custom-file" style={{zIndex: 0}}>
                    <input
                      type="file"
                      className={this.state.errors.participant_csv_file ? 'custom-file-input is-invalid': 'custom-file-input'}
                      name="participant_csv_file"
                      onChange={this.onFileChange.bind(this, 'participant_csv_file')}
                    />
                    <label className="custom-file-label" htmlFor="customFile">{this.state.values.participant_csv_file ? this.state.values.participant_csv_file_name : 'Choose file'}</label>
                    {this.state.errors.participant_csv_file ? <div className="invalid-feedback">This field is mandatory</div> : null}
                  </div>
                </div>

              </CardBody>

              <div className="card-footer">
                {this.state.loading || this.state.done ?
                  <button disabled={true} type="submit" onClick={this.onSubmit.bind(this)} className="btn btn-primary mr-2 disabled spinner spinner-left">Submit</button> :
                  <button type="submit" onClick={this.onSubmit.bind(this)} className="btn btn-primary mr-2">Submit</button>}
              </div>
            </div>
          </Card>

        </div>
      </div>
    </>
  }
}

export default connect(
  state => ({
    ...state.app,
  }),
  actions
)(ParticipationImport);