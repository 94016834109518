import React, { Component } from 'react';
import {Card, CardBody, CardHeader, CardHeaderToolbar} from "../../partials/controls";
import {Row, Col} from "react-bootstrap"


class FM2Card extends Component {

  render() {
    return <Card className="card card-custom">
      <CardHeader>
        <div className="card-title">
          <h3 className="card-label">
            [Filemaker V2: {this.props.data.recordId}] {this.props.data.fieldData.Prenom} {this.props.data.fieldData.Nom}
          </h3>
        </div>
        <CardHeaderToolbar>
        </CardHeaderToolbar>
      </CardHeader>
      <div>
        <CardBody>

          <Row>

            <Col xs={12} xl={4}>

              <div className="d-flex align-items-center justify-content-between mb-2">
                <span className="font-weight-bold mr-2">Prénom:</span>
                <span className="text-muted">{this.props.data.fieldData.Prenom}</span>
              </div>

              <h3 className='mt-10'>Adresses</h3>

              <table className="table table-sm">
                <thead>
                <tr>
                  <th scope="col">Adresse</th>
                  <th scope="col">Ville</th>
                  <th scope="col">Province</th>
                  <th scope="col">Pays</th>
                  <th scope="col">ZIP</th>
                </tr>
                </thead>
                <tbody>
                {this.props.data.portalData.Adresse.map((address, i) => {
                  return <tr key={i}>
                    <td>{address.Address}</td>
                    <td>{address.City}</td>
                    <td>{address.State}</td>
                    <td>{address.Country}</td>
                    <td>{address.Zip}</td>
                  </tr>
                })}
                </tbody>
              </table>

            </Col>

            <Col xs={12} xl={4}>

              <div className="d-flex align-items-center justify-content-between mb-2">
                <span className="font-weight-bold mr-2">Nom:</span>
                <span className="text-muted">{this.props.data.fieldData.Nom}</span>
              </div>

              <h3 className='mt-10'>Telephones</h3>

              <table className="table table-sm">
                <thead>
                <tr>
                  <th scope="col">Telephone</th>
                  <th scope="col">Type</th>
                </tr>
                </thead>
                <tbody>
                {this.props.data.portalData.Telephone.map((phone, i) => {
                  if(phone['Telephone::Masque'] !== "() -") {
                    return <tr key={i}>
                      <td>{phone['Telephone::Masque']}</td>
                      <td>{phone['Telephone::Type']}</td>
                    </tr>
                  }
                })}
                </tbody>
              </table>

            </Col>

            <Col xs={12} xl={4}>

              <div className="d-flex align-items-center justify-content-between mb-2">
                <span className="font-weight-bold mr-2">Langue:</span>
                <span className="text-muted">{this.props.data.fieldData.Langue}</span>
              </div>

              <h3 className='mt-10'>Courriels</h3>

              <table className="table table-sm">
                <thead>
                <tr>
                  <th scope="col">Courriel</th>
                </tr>
                </thead>
                <tbody>
                {this.props.data.portalData.Courriel.map((email, i) => {
                  return <tr key={i}>
                    <td>{email['Courriel::Courriel']}</td>
                  </tr>
                })}
                </tbody>
              </table>

            </Col>

          </Row>

        </CardBody>
      </div>
    </Card>
  }
}

export default FM2Card;