import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { handleAuthentication } from '../Auth0';
import { all, call, put, takeLatest } from 'redux-saga/effects';

export const actionTypes = {
  HandleAuthentication: "[Login] Handle Authentication Callback",
  TokenReceived: "[Auth0] Token Received",
  AuthenticationError: "[Auth0] Authentication Error",
  SessionIsExpired: "[Auth0] Session is Expired",
  LogOff: "[Auth0] Logging Off",
};

const initialAuthState = {
  token: undefined,
  authenticated: false,
  expires_at: undefined,
  loading: true,
  success: undefined
};

export const reducer = persistReducer(
  { storage, key: "auth", whitelist: ["token", "expires_at"] },
  (state = initialAuthState, action) => {
    switch (action.type) {

      case actionTypes.HandleAuthentication: {
        return initialAuthState
      }

      case actionTypes.TokenReceived: {
        return {
          ...state,
          authenticated: action.user.authenticated,
          token: action.user.accessToken,
          expires_at: action.user.expiresAt,
          success: true,
          loading: false
        };
      }

      case actionTypes.AuthenticationError: {
        return {
          ...state,
          success: false,
          loading: false
        }
      }

      case actionTypes.SessionIsExpired: {
        return {
          ...initialAuthState
        }
      }

      case actionTypes.LogOff: {
        return {
          ...initialAuthState
        }
      }

      default:
        const currentTimeInSeconds = new Date().getTime() / 1000;
        return {
          ...state,
          authenticated: currentTimeInSeconds < state.expires_at
        };
    }
  }
);

export const actions = {
  handleAuthenticationCallback: () => ({ type: actionTypes.HandleAuthentication }),
  expireSession: () => ({ type: actionTypes.SessionIsExpired }),
  logOff: () => ({ type: actionTypes.LogOff }),
};

export function* parseHash() {
  try {
    const user = yield call(handleAuthentication);
    yield put({ type: actionTypes.TokenReceived, user });
  } catch {
    yield put({ type: actionTypes.AuthenticationError });
  }
}

export function* handleAuthenticationCallback() {
  yield takeLatest(actionTypes.HandleAuthentication, parseHash);
}

// replace the current rootSaga generator
export function* rootSaga() {
  yield all([handleAuthenticationCallback()]);
}
