const actions = {
  // UI Elements
  ToggleOffCanvas: "[UI] Toogle Off Canvas",

  toggleOffCanvas: (canvas_key, data= undefined) => ({
    type: actions.ToggleOffCanvas,
    key: canvas_key,
    data: data
  }),
};

export default actions;
