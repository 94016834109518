import actions from './actions';
import storage from "redux-persist/lib/storage";
import {persistReducer} from "redux-persist";

const initialState = {
  loading: false,
  off_canvas: {
    user: {
      show: false,
      data: {}
    }
  },
  last_location: undefined
};

export const appReducer = persistReducer(
  { storage, key: "app", whitelist: ["last_location"] },
  (state = initialState, action) => {
    switch (action.type) {

      case actions.ToggleOffCanvas:
        return {
          ...state,
          off_canvas: {
            ...state.off_canvas,
            [action.key]: {
              show: !state.off_canvas[action.key]['show'],
              data: action.data
            }
          }
        }

      case '@@router/LOCATION_CHANGE':
        const last_location = action.payload.location.pathname !== '/callback' ? action.payload.location.pathname : state.last_location
        return {
          ...state,
          last_location: last_location
        }

      default:
        return state;
    }
  }
);