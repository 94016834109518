import React, { Component } from 'react';


class Error404 extends Component {

  render() {

    return <div className="d-flex flex-column-fluid">
      <div className="container">

        <div style={{textAlign: 'center'}} >

          <h1
            className="font-size-sm-100 font-weight-boldest text-dark-75"
            style={{ fontSize: "150px" }}
          >
            404
          </h1>

          <p className="font-size-h3 font-weight-light">
            The page you requested does not exists!
          </p>

        </div>

      </div>
    </div>
  }
}

export default Error404