/**
 * Entry application component used to compose providers and render Routes.
 * */

import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Routes } from "./router";
import { LayoutSplashScreen, MaterialThemeProvider } from "./layout";
import { ConnectedRouter } from 'connected-react-router'
import { history } from './redux/store'
import ReduxToastr from "react-redux-toastr";
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

export default function BootApp({ store, persistor, basename }) {
  return (
    /* Provide Redux store */
    <Provider store={store}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
        {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
        <React.Suspense fallback={<LayoutSplashScreen />}>
          {/* Override `basename` (e.g: `homepage` in `package.json`) */}
          <ConnectedRouter history={history} basename={basename}>
            <ToastContainer position="top-right"
                            autoClose={5000}
                            hideProgressBar={true}
                            newestOnTop={false}
                            closeOnClick
                            draggable={false}
                            rtl={false}/>
            {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
            <MaterialThemeProvider>
              {/* Render routes with provided `Layout`. */}
              <Routes />
            </MaterialThemeProvider>
          </ConnectedRouter>
        </React.Suspense>
      </PersistGate>
    </Provider>
  );
}
