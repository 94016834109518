/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {useLocation} from "react-router";
import {NavLink}  from "react-router-dom";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl, checkIsActive} from "../../../helpers";
import {useSelector} from "react-redux";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const { me, database } = useSelector(state => state.app);
  const getMenuItemActive = (url, exact=false) => {
    return checkIsActive(location, url, exact)
        ? " menu-item-active menu-item-open "
        : "";
  };

  return (
      <>
        {/* begin::Menu Nav */}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          {/*begin::1 Level*/}
          <li
            className={`menu-item ${getMenuItemActive("/", true)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}/>
            </span>
              <span className="menu-text">Modifications de Contact</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}

          {/* Components */}
          {/* begin::section */}
          <li className="menu-section ">
            <h4 className="menu-text">Formulaires</h4>
            <i className="menu-icon flaticon-more-v2" />
          </li>
          {/* end:: section */}

          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/forms/pda"
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to={"/forms/pda"}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Cap-2.svg")}/>
            </span>
              <span className="menu-text">Place des Arts</span>
              <i className="menu-arrow"/>
            </NavLink>
            <div className="menu-submenu ">
              <i className="menu-arrow"/>
              <ul className="menu-subnav">
                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                    <span className="menu-link">
                      <span className="menu-text">test1</span>
                    </span>
                </li>

                <li className={`menu-item  ${getMenuItemActive("/forms/pda/import")}`} aria-haspopup="true">
                  <NavLink className="menu-link" to={"/forms/pda/import"}>
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">Arthics: Import Journalier</span>
                  </NavLink>
                </li>

                <li className={`menu-item  ${getMenuItemActive("/forms/pda/client_import")}`} aria-haspopup="true">
                  <NavLink className="menu-link" to={"/forms/pda/client_import"}>
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">Arthics: Coordonnées Client</span>
                  </NavLink>
                </li>
              </ul>
            </div>
          </li>

          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/forms/other"
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to={"/forms/other"}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Cap-2.svg")}/>
            </span>
              <span className="menu-text">Autre</span>
              <i className="menu-arrow"/>
            </NavLink>
            <div className="menu-submenu ">
              <i className="menu-arrow"/>
              <ul className="menu-subnav">
                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                    <span className="menu-link">
                      <span className="menu-text">other forms</span>
                    </span>
                </li>

                <li className={`menu-item  ${getMenuItemActive("/forms/other/participant_import")}`} aria-haspopup="true">
                  <NavLink className="menu-link" to={"/forms/other/participant_import"}>
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">Participations</span>
                  </NavLink>
                </li>

                <li className={`menu-item  ${getMenuItemActive("/qb/create_classes")}`} aria-haspopup="true">
                  <NavLink className="menu-link" to={"/qb/create_classes"}>
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">QA: Create Classes</span>
                  </NavLink>
                </li>
              </ul>
            </div>
          </li>

          {/* Components */}
          {/* begin::section */}
          <li className="menu-section ">
            <h4 className="menu-text">Base de Données</h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li>
          {/* end:: section */}

          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/data/fm/"
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to={"/data/fm/"}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Cap-2.svg")}/>
            </span>
              <span className="menu-text">FileMaker</span>
              <i className="menu-arrow"/>
            </NavLink>
            <div className="menu-submenu ">
              <i className="menu-arrow"/>
              <ul className="menu-subnav">
                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                    <span className="menu-link">
                      <span className="menu-text">Filemaker</span>
                    </span>
                </li>

                <li className={`menu-item  ${getMenuItemActive("/data/fm/v1")}`} aria-haspopup="true">
                  <NavLink className="menu-link" to={"/data/fm/v1"}>
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">Recherche: Clients V1</span>
                  </NavLink>
                </li>

                <li className={`menu-item  ${getMenuItemActive("/data/fm/v2")}`} aria-haspopup="true">
                  <NavLink className="menu-link" to={"/data/fm/v2"}>
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">Recherche: Clients V2</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}
              </ul>
            </div>
          </li>


        </ul>

        {/* end::Menu Nav */}
      </>
  );
}
