import React, { Component } from 'react';


class QuickbooksSetup extends Component {

  constructor(props) {
    super(props);
  }

  render() {

    return <>

    </>
  }
}

export default QuickbooksSetup;