import React, { Component } from 'react';
import {Card, CardBody, CardHeader, CardHeaderToolbar, Pagination} from "../../partials/controls";
import {connect} from "react-redux";
import actions from "../../_redux/actions";
import {apiService} from "../../services/api";
import FM1Card from "../../components/cards/FM1Card";
import FM2Card from "../../components/cards/FM2Card";
import {Row, Col, Dropdown} from "react-bootstrap"
import Error404 from "../../../errors/404";
import {LogOffButton} from "../../../auth/Auth0";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";


class SFContactSearch extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      profile: null
    }
  }

  async componentDidMount() {
    if(this.props.match.params.sfContactId) {
      this.runSearchAlert = withReactContent(Swal)
      await this.loadSFContact()
    }
  }

  async loadSFContact() {
    const {response, error} = await apiService.salesforce.contact.get(this.props.match.params.sfContactId)

    if (response.status > 300) {
      this.setState({
        loading: false,
        profile: null
      })
    } else {
      this.setState({
        loading: false,
        profile: response.data
      })
    }

  }

  onChange(e) {
    this.setState({
      search: e.target.value,
      error: ''
    })
  }

  async removeFromContact(pdaAccount) {
    await Swal.fire({
      title: 'Êtes-vous certain de vouloir retirer ce compte PDA du Contact?',
      text: "Cette opération est irreversible!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, remove it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        await this.runSearchAlert.fire({
          title: "Opération en cours...",
          text: "Veuillez attendre que cette opération soit terminé.",
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showConfirmButton: false,
          onOpen: async () => {
            this.runSearchAlert.showLoading();
            const { response, error } = await apiService.salesforce.toolkit.pda_account.removeFromContact(pdaAccount.Id)
            if (response.status >= 200 && response.status < 300) {
              await this.loadSFContact()
              await Swal.fire(
                'Le Compte PDA à été retirer du Contact!',
                'success'
              )
            } else {
              alert('Une erreur à eu lieu! Veuillez ré-essayer plus tard.')
            }
            this.runSearchAlert.close()
          }
        })
      }
    })
  }

  async assignToContact(pdaAccount) {
    const { value: contactId } = await Swal.fire({
      title: 'Assignation à un Contact',
      text: "Veuillez entrer le ID du Contact Salesforce à qui vous souhaitez attribuer ce Compte PDA.",
      input: 'text',
      inputLabel: 'Salesforce Contact ID',
      showCancelButton: true,
      inputValidator: async (value) => {
        const {response, error} = await apiService.salesforce.contact.get(value)
        if(response.status > 300) {
          return 'Salesforce Contact introuvable!'
        } else {
          await this.loadSFContact()
        }
      }
    })

    if (contactId) {
      await this.runSearchAlert.fire({
        title: "Opération en cours...",
        text: "Veuillez attendre que cette opération soit terminé.",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
        onOpen: async () => {
          this.runSearchAlert.showLoading();
          const { response, error } = await apiService.salesforce.toolkit.pda_account.assign(pdaAccount.Id, contactId)
          if (response.status > 300) {
            alert('Une erreur à eu lieu! Veuillez ré-essayer plus tard.')
          } else {
            await this.loadSFContact()
          }
          this.runSearchAlert.close()
        }
      })
    }
  }

  render() {

    if (this.state.loading) {
      return null;
    } else if(this.state.loading === false && this.state.profile === null) {
      return <Error404 />
    }

    return <>
      <div id="kt_subheader" className={`subheader py-2 py-lg-4 subheader-solid`}>
        <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
          {/* Info */}
          <div className="d-flex align-items-center flex-wrap mr-2">
            <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Salesforce</h5>
            <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-4 bg-gray-200" />
            <span className="text-muted font-weight-bold mr-4">Contact</span>
            <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-4 bg-gray-200" />
            <span className="text-muted font-weight-bold mr-4">{this.state.profile.Name}</span>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <LogOffButton />
        </div>
      </div>
      <div className="d-flex flex-column-fluid">
        <div className="container-fluid">

          <Card className="card card-custom">
            <CardHeader>
              <div className="card-title">
                <h3 className="card-label">
                  {this.state.profile.Name}
                </h3>
              </div>
              <CardHeaderToolbar>
                {/*<Dropdown className="dropdown-inline" drop="down">*/}
                {/*  <Dropdown.Toggle bsPrefix='toggle' className="nav-link dropdown-toggle" title="Forward">*/}
                {/*    <span className="nav-icon">*/}
                {/*      <i className="flaticon2-rocket-1"></i>*/}
                {/*    </span>*/}
                {/*    <span className="nav-text">Actions</span>*/}
                {/*  </Dropdown.Toggle>*/}

                {/*  <Dropdown.Menu>*/}
                {/*    <Dropdown.Item><i className="fa fa-building mr-2" />Reconstruire les Abonnements</Dropdown.Item>*/}
                {/*    <Dropdown.Item><i className="fa fa-building mr-2" />Mettre à Jour les Achats</Dropdown.Item>*/}
                {/*  </Dropdown.Menu>*/}
                {/*</Dropdown>*/}
              </CardHeaderToolbar>
            </CardHeader>
            <div>
              <CardBody>

                <Row>

                  <Col xs={12} xl={4}>

                    <div className="d-flex align-items-center justify-content-between mb-2">
                      <span className="font-weight-bold mr-2">Prénom:</span>
                      <span className="text-muted">{this.state.profile.FirstName}</span>
                    </div>

                    <div className="d-flex align-items-center justify-content-between mb-2">
                      <span className="font-weight-bold mr-2">Nom:</span>
                      <span className="text-muted">{this.state.profile.LastName}</span>
                    </div>

                    <div className="d-flex align-items-center justify-content-between mb-2">
                      <span className="font-weight-bold mr-2">Courriel 1:</span>
                      <span className="text-muted">{this.state.profile.Email}</span>
                    </div>

                    <div className="d-flex align-items-center justify-content-between mb-2">
                      <span className="font-weight-bold mr-2">Courriel 2:</span>
                      <span className="text-muted">{this.state.profile.OtherEmail__c}</span>
                    </div>

                  </Col>

                  <Col xs={12} xl={4}>

                    <div className="d-flex align-items-center justify-content-between mb-2">
                      <span className="font-weight-bold mr-2">Adresse:</span>
                      <span className="text-muted">{this.state.profile.MailingStreet}</span>
                    </div>

                    <div className="d-flex align-items-center justify-content-between mb-2">
                      <span className="font-weight-bold mr-2">Ville:</span>
                      <span className="text-muted">{this.state.profile.MailingCity}</span>
                    </div>

                    <div className="d-flex align-items-center justify-content-between mb-2">
                      <span className="font-weight-bold mr-2">Province/State:</span>
                      <span className="text-muted">{this.state.profile.MailingState}</span>
                    </div>

                    <div className="d-flex align-items-center justify-content-between mb-2">
                      <span className="font-weight-bold mr-2">Pays:</span>
                      <span className="text-muted">{this.state.profile.MailingCountry}</span>
                    </div>

                    <div className="d-flex align-items-center justify-content-between mb-2">
                      <span className="font-weight-bold mr-2">Code Postal:</span>
                      <span className="text-muted">{this.state.profile.MailingPostalCode}</span>
                    </div>

                  </Col>

                  <Col xs={12} xl={4}>

                    <div className="d-flex align-items-center justify-content-between mb-2">
                      <span className="font-weight-bold mr-2">Langue:</span>
                      <span className="text-muted">{this.state.profile.Langue__c}</span>
                    </div>

                    <div className="d-flex align-items-center justify-content-between mb-2">
                      <span className="font-weight-bold mr-2">Téléphone:</span>
                      <span className="text-muted">{this.state.profile.Phone}</span>
                    </div>

                    <div className="d-flex align-items-center justify-content-between mb-2">
                      <span className="font-weight-bold mr-2">Téléphone Mobile:</span>
                      <span className="text-muted">{this.state.profile.MobilePhone}</span>
                    </div>

                    <div className="d-flex align-items-center justify-content-between mb-2">
                      <span className="font-weight-bold mr-2">Téléphone Maison:</span>
                      <span className="text-muted">{this.state.profile.HomePhone}</span>
                    </div>

                    <div className="d-flex align-items-center justify-content-between mb-2">
                      <span className="font-weight-bold mr-2">Téléphone Autre:</span>
                      <span className="text-muted">{this.state.profile.OtherPhone}</span>
                    </div>

                  </Col>

                </Row>

                <Row>

                  <Col xs={12}>

                    <h3>Compte PDAs</h3>

                    <table className="table table-sm">
                      <thead>
                      <tr>
                        <th scope="col">PDA #ID</th>
                        <th scope="col">Nom du Client</th>
                        <th scope="col">Langue</th>
                        <th scope="col">Adresse</th>
                        <th scope="col">Telephone</th>
                        <th scope="col">Actions</th>
                      </tr>
                      </thead>
                      <tbody>
                      {this.state.profile.PDA_Accounts.map((pdaAccount, i) => {
                        return <tr key={i}>
                          <td>{pdaAccount.Account_ID__c}</td>
                          <td>{pdaAccount.owner_name__c}</td>
                          <td>{pdaAccount.language_name__c}</td>
                          <td>{pdaAccount.street_addr_1__c}, {pdaAccount.city__c}, {pdaAccount.state__c}, {pdaAccount.country__c}, {pdaAccount.zip__c}</td>
                          <td>{pdaAccount.phone_day__c}</td>
                          <td>
                            <button onClick={this.assignToContact.bind(this, pdaAccount)} className="btn btn-light btn-sm" title="Re-Assign">
                              <i className="fa fa-arrow-alt-circle-right" /> Ré-assignier
                            </button>
                            <button onClick={this.removeFromContact.bind(this, pdaAccount)} className="btn btn-light btn-sm ml-2" title="Retirer">
                              <i className="fa fa-trash" /> Retirer du Contact
                            </button>
                          </td>
                        </tr>
                      })}
                      </tbody>
                    </table>

                  </Col>

                </Row>

              </CardBody>
            </div>
          </Card>

          {this.state.profile && this.state.profile.filemaker_v1.map((fm1_item, i) => {
            return <FM1Card key={i} data={fm1_item} />
          })}

          {this.state.profile && this.state.profile.filemaker_v2.map((fm2_item, i) => {
            return <FM2Card key={i} data={fm2_item} />
          })}

        </div>
      </div>
    </>
  }
}

export default connect(
  state => ({
    ...state.app,
  }),
  actions
)(SFContactSearch);