import React, { Component } from 'react';
import {Card, CardBody, CardHeader, CardHeaderToolbar, Pagination} from "../../partials/controls";
import {connect} from "react-redux";
import actions from "../../_redux/actions";
import {apiService} from "../../services/api";
import FM2Card from "../../components/cards/FM2Card";
import {LogOffButton} from "../../../auth/Auth0";


class FMV2Search extends Component {

  constructor(props) {
    super(props);

    this.state = {
      search: '',
      profile: null,
      error: ''
    }
  }

  async componentDidMount() {
    if(this.props.match.params.fmv2Id) {
      await this.setState({
        ...this.state,
        search: this.props.match.params.fmv2Id
      })

      await this.loadFMProfile()
    }
  }


  async loadFMProfile() {
    const {response, error} = await apiService.filemaker.v2.get(this.state.search)

    if (response) {
      this.setState({
        search: '',
        profile: response.data,
        error: ''
      })
    } else {
      this.setState({
        search: '',
        profile: null,
        error: error.response ? error.response.data.error : 'Ce champ est obligatoire.'
      })
    }

  }

  onChange(e) {
    this.setState({
      search: e.target.value,
      error: ''
    })
  }

  render() {

    return <>
      <div id="kt_subheader" className={`subheader py-2 py-lg-4 subheader-solid`}>
        <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
          {/* Info */}
          <div className="d-flex align-items-center flex-wrap mr-2">
            <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">FileMaker</h5>
            <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-4 bg-gray-200" />
            <span className="text-muted font-weight-bold mr-4">Recherche</span>
            <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-4 bg-gray-200" />
            <span className="text-muted font-weight-bold mr-4">Base de Données V2</span>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <LogOffButton />
        </div>
      </div>
      <div className="d-flex flex-column-fluid">
        <div className="container-fluid">

          <Card className="card card-custom">
            <CardHeader>
              <div className="card-title">
                <h3 className="card-label">
                  FileMaker: Recherche V2
                </h3>
              </div>
              <CardHeaderToolbar>
              </CardHeaderToolbar>
            </CardHeader>
            <div>
              <CardBody>

                <div className="form-group">
                  <label>FileMaker V2 ID</label>
                  <div className="input-group">
                    <input
                      type="text"
                      className={!this.state.error ? "form-control" : "form-control is-invalid"}
                      onChange={this.onChange.bind(this)}
                      value={this.state.search}
                      placeholder="Entrer le ID ici..."
                    />
                    <div className="input-group-append">
                      <button className="btn btn-primary" type="button" onClick={this.loadFMProfile.bind(this)}>Charger le profile Filemaker</button>
                    </div>
                    {this.state.error ? <div className="invalid-feedback">{this.state.error}</div> : null}
                  </div>
                </div>

              </CardBody>
            </div>
          </Card>

          {this.state.profile !== null ? <FM2Card data={this.state.profile} /> : null}

        </div>
      </div>
    </>
  }
}

export default connect(
  state => ({
    ...state.app,
  }),
  actions
)(FMV2Search);