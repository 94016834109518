import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from './_redux/actions';
import {Layout} from "./components/Layout";
import { LayoutSplashScreen } from "../layout";
import {AppRoutes} from "./Routes"
import {signIn} from "../auth/Auth0"


class App extends Component {

  UNSAFE_componentWillMount() {
    if(this.props.auth.authenticated) {
      const tokenExpiryInMilliseconds = this.props.auth.expires_at * 1000;
      const millisecondsLeftToSession = tokenExpiryInMilliseconds - Date.now();
      this.sessionExpTimer = setInterval(() => this.renewSession(), millisecondsLeftToSession - 5000);
    } else {
      signIn()
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
    if(!nextProps.auth.authenticated) {
      signIn()
    }
  }

  renewSession() {
    signIn()
  }

  render() {

    if (this.props.app.loading) {

      return <LayoutSplashScreen />

    } else {

      return <Layout>
        <AppRoutes />
      </Layout>

    }
  }
}

export default connect(
  state => ({
    ...state,
  }),
  actions
)(App);