// ./src/Auth.js
import React from "react";
import auth0 from 'auth0-js';
import config from "../app/Config"
import {useDispatch} from "react-redux";
import storage from 'redux-persist/lib/storage'

const auth0Client = new auth0.WebAuth({
  // the following three lines MUST be updated
  domain: config.auth0.domain,
  audience: config.auth0.audience,
  clientID: config.auth0.clientId,
  redirectUri: config.auth0.redirectUri,
  responseType: 'token',
  scope: 'openid profile email'
});

export function handleAuthentication() {
  return new Promise((resolve, reject) => {
    auth0Client.parseHash((err, authResult) => {
      if (err) return reject(err);
      if (!authResult || !authResult.accessToken) {
        return reject(err);
      }
      const accessToken = authResult.accessToken;
      const currentTimeInSeconds = new Date().getTime() / 1000;
      const expiresAt = currentTimeInSeconds + authResult.expiresIn;
      resolve({
        authenticated: true,
        accessToken,
        expiresAt
      });
    });
  });
}

export function signIn() {
  auth0Client.authorize();
}

export function signOut() {
  auth0Client.logout({
    // returnTo: config.auth0.returnTo,
    clientID: config.auth0.clientId
  });
}

export const LogOffButton = () => {
  return (
    <button className="btn btn-outline-success font-weight-bolder btn-sm mr-2" onClick={() => {
      storage.removeItem('persist:auth')
      signOut();
    }}>
      Déconnexion
    </button>
  );
}