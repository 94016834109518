// ./src/containers/Callback.js

import { LayoutSplashScreen } from "../layout";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {actions} from './_redux/authRedux';
import { signIn } from './Auth0';


class AuthCallback extends Component {

  componentDidMount() {
    if (this.props.location.hash) {
      this.props.handleAuthenticationCallback();
    }
  }

  componentWillUpdate(nextProps, nextState, nextContext) {
    if(nextProps.auth.loading === false) {
      if(nextProps.auth.success === true) {
        this.props.history.push(nextProps.app.last_location)
      } else {
        signIn()
      }
    }
  }

  render() {
    return <LayoutSplashScreen />
  }
}

export default connect(
  state => ({
    ...state,
  }),
  actions
)(AuthCallback);