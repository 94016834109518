import axios from "axios";
import config from "../Config"

export const SF_PDA_ACCOUNT_QUEUE = config.api_endpoint + "/sf/pda_account_queue";
export const FM_CONTACT_V1 = config.api_endpoint + "/fm1/";
export const FM_CONTACT_V2 = config.api_endpoint + "/fm2/";
export const SF_CONTACT = config.api_endpoint + "/sf/contact/";
export const SF_PDA_ACCOUNT = config.api_endpoint + "/sf/pda_account/";
export const SF_SEASON = config.api_endpoint + "/sf/season";
export const SF_EVENTS = config.api_endpoint + "/sf/events";
export const SF_DONATION_RECEIPT = config.api_endpoint + "/sf/donation/";

export const TASKS_TICKET_IMPORT = config.api_endpoint + "/tasks/pda/tickets_import";
export const TASKS_CLIENT_UPDATE = config.api_endpoint + "/tasks/pda/client_update";
export const TASKS_EMAIL_UPDATE = config.api_endpoint + "/tasks/pda/email_update";
export const TASKS_PARTICIPANT_IMPORT = config.api_endpoint + "/tasks/pda/participant_import";

export const CONTACT_MOD = config.api_endpoint + "/modifications";

export function api_get_fm_v1(fm1_id) {
  return axios.get(FM_CONTACT_V1 + fm1_id)
    .then(response => ({ response }))
    .catch(error => {
      return ({ error });
    });
}

export function api_get_fm_v2(fm2_id) {
  return axios.get(FM_CONTACT_V2 + fm2_id)
    .then(response => ({ response }))
    .catch(error => {
      return ({ error });
    });
}

export function api_get_sf_contact(sf_id) {
  return axios.get(SF_CONTACT + sf_id)
    .then(response => ({ response }))
    .catch(error => {
      return ({ error });
    });
}

export function api_contact_rebuild_attendance(sf_id) {
  return axios.post(SF_CONTACT + sf_id + '/rebuild-attendance')
    .then(response => ({ response }))
    .catch(error => {
      return ({ error });
    });
}

export function api_get_all_sf_season() {
  return axios.get(SF_SEASON)
    .then(response => ({ response }))
    .catch(error => {
      return ({ error });
    });
}

export function api_get_all_sf_events() {
  return axios.get(SF_EVENTS)
    .then(response => ({ response }))
    .catch(error => {
      return ({ error });
    });
}

export function api_get_sf_contact_pda_accounts(sf_id) {
  return axios.get(SF_CONTACT + sf_id + '/pda_accounts')
    .then(response => ({ response }))
    .catch(error => {
      return ({ error });
    });
}

export function api_get_sf_donation(sf_donation_id) {
  return axios.get(SF_DONATION_RECEIPT + sf_donation_id + '/receipt')
    .then(response => ({ response }))
    .catch(error => {
      return ({ error });
    });
}

export function api_get_pda_account_queue() {
  return axios.get(SF_PDA_ACCOUNT_QUEUE)
    .then(response => ({ response }))
    .catch(error => {
      return ({ error });
    });
}

export function api_tasks_tickets_import(form_data) {
  return axios.post(TASKS_TICKET_IMPORT, form_data,
    {
      headers: {
        "Content-type": "multipart/form-data",
      },
    })
    .then(response => ({ response }))
    .catch(error => {
      return ({ error });
    });
}

export function api_tasks_participations_import(form_data) {
  return axios.post(TASKS_PARTICIPANT_IMPORT, form_data,
    {
      headers: {
        "Content-type": "multipart/form-data",
      },
    })
    .then(response => ({ response }))
    .catch(error => {
      return ({ error });
    });
}

export function api_tasks_client_update(form_data) {
  return axios.post(TASKS_CLIENT_UPDATE, form_data,
    {
      headers: {
        "Content-type": "multipart/form-data",
      },
    })
    .then(response => ({ response }))
    .catch(error => {
      return ({ error });
    });
}

export function api_tasks_email_update(form_data) {
  return axios.post(TASKS_EMAIL_UPDATE, form_data,
    {
      headers: {
        "Content-type": "multipart/form-data",
      },
    })
    .then(response => ({ response }))
    .catch(error => {
      return ({ error });
    });
}

export function api_pda_acc_assign_to_contact(sf_id, new_contact_id) {
  return axios.post(SF_PDA_ACCOUNT + sf_id + '/assign-to-contact', {new_contact_id: new_contact_id})
    .then(response => ({ response }))
    .catch(error => {
      return ({ error });
    });
}

export function api_pda_acc_create_new_contact(sf_id) {
  return axios.post(SF_PDA_ACCOUNT + sf_id + '/create-new-contact')
    .then(response => ({ response }))
    .catch(error => {
      return ({ error });
    });
}

export function api_pda_acc_make_orphan(sf_id) {
  return axios.post(SF_PDA_ACCOUNT + sf_id + '/make-orphan')
    .then(response => ({ response }))
    .catch(error => {
      return ({ error });
    });
}

export function api_pda_acc_rebuild_subscriptions(sf_id) {
  return axios.post(SF_PDA_ACCOUNT + sf_id + '/rebuild-subscriptions')
    .then(response => ({ response }))
    .catch(error => {
      return ({ error });
    });
}

export function api_pda_acc_remove_from_contact(sf_id) {
  return axios.post(SF_PDA_ACCOUNT + sf_id + '/remove')
    .then(response => ({ response }))
    .catch(error => {
      return ({ error });
    });
}

export function api_get_contact_modifications() {
  return axios.get(CONTACT_MOD)
    .then(response => ({ response }))
    .catch(error => {
      return ({ error });
    });
}

export function api_contact_modification_accept(modification_id) {
  return axios.post(CONTACT_MOD + '/' + modification_id + '/accept')
    .then(response => ({ response }))
    .catch(error => {
      return ({ error });
    });
}

export function api_contact_modification_refuse(modification_id) {
  return axios.post(CONTACT_MOD + '/' + modification_id + '/refuse')
    .then(response => ({ response }))
    .catch(error => {
      return ({ error });
    });
}

export function api_quickbooks_create_classes(season_name) {
  return axios.post(config.api_endpoint + '/qb/create-classes', {season_name})
    .then(response => ({ response }))
    .catch(error => {
      return ({ error });
    });
}

export const apiService = {
  tasks: {
    tickets_import: api_tasks_tickets_import,
    client_update: api_tasks_client_update,
    email_update: api_tasks_email_update,
    participant_import: api_tasks_participations_import,
  },

  filemaker: {
    v1: {
      get: api_get_fm_v1
    },
    v2: {
      get: api_get_fm_v2
    }
  },

  contact_mod: {
    getAll: api_get_contact_modifications,
    accept: api_contact_modification_accept,
    refuse: api_contact_modification_refuse
  },

  quickbooks: {
    createClasses: api_quickbooks_create_classes
  },

  salesforce: {
    contact: {
      get: api_get_sf_contact,
      getPDAAccounts: api_get_sf_contact_pda_accounts,
      rebuildAttendance: api_contact_rebuild_attendance,
    },
    donation: {
      getReceipt: api_get_sf_donation
    },
    pda_account: {
      getOrphans: api_get_pda_account_queue
    },
    season: {
      getAll: api_get_all_sf_season
    },
    events: {
      getAll: api_get_all_sf_events
    },
    toolkit: {
      pda_account: {
        assign: api_pda_acc_assign_to_contact,
        createContact: api_pda_acc_create_new_contact,
        makeOrphan: api_pda_acc_make_orphan,
        rebuildSubscriptions: api_pda_acc_rebuild_subscriptions,
        removeFromContact: api_pda_acc_remove_from_contact
      }
    }
  }
}