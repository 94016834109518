/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { Route, Switch } from 'react-router'
import AuthCallback from "./auth/AuthCallback";
import Error404 from "./errors/404";
import App from "./app/App";
import QuickbooksSetup from "./app/modules/Quickbooks/QuickbooksSetup";


export function Routes() {
  return (
    <Switch>
      <Route path="/callback" component={AuthCallback}/>
      <Route path="/quickbooks-setup" component={QuickbooksSetup}/>

      <Route path="" exact component={App}/>

      <Route component={Error404} />
    </Switch>
  );
}
