import React, { Component } from 'react';
import {Card, CardBody, CardHeader, CardHeaderToolbar} from "../../partials/controls";
import {Row, Col} from "react-bootstrap"


class FM1Card extends Component {

  render() {
    return <Card className="card card-custom">
      <CardHeader>
        <div className="card-title">
          <h3 className="card-label">
            [Filemaker V1: {this.props.data.recordId}] {this.props.data.fieldData.nPrenom_Nom}
          </h3>
        </div>
        <CardHeaderToolbar>
        </CardHeaderToolbar>
      </CardHeader>
      <div>
        <CardBody>

          <Row>

            <Col xs={12} xl={4}>

              <div className="d-flex align-items-center justify-content-between mb-2">
                <span className="font-weight-bold mr-2">Prénom:</span>
                <span className="text-muted">{this.props.data.fieldData.nPrenom}</span>
              </div>

              <div className="d-flex align-items-center justify-content-between mb-2">
                <span className="font-weight-bold mr-2">Nom:</span>
                <span className="text-muted">{this.props.data.fieldData.nNom}</span>
              </div>

              <div className="d-flex align-items-center justify-content-between mb-2">
                <span className="font-weight-bold mr-2">Courriel 1:</span>
                <span className="text-muted">{this.props.data.fieldData['bCourriel 1']}</span>
              </div>

              <div className="d-flex align-items-center justify-content-between mb-2">
                <span className="font-weight-bold mr-2">Courriel 2:</span>
                <span className="text-muted">{this.props.data.fieldData['bCourriel 2']}</span>
              </div>

            </Col>

            <Col xs={12} xl={4}>

              <div className="d-flex align-items-center justify-content-between mb-2">
                <span className="font-weight-bold mr-2">Adresse:</span>
                <span className="text-muted">{this.props.data.fieldData.bAdresse.Address}</span>
              </div>

              <div className="d-flex align-items-center justify-content-between mb-2">
                <span className="font-weight-bold mr-2">Ville:</span>
                <span className="text-muted">{this.props.data.fieldData.bAdresse.City}</span>
              </div>

              <div className="d-flex align-items-center justify-content-between mb-2">
                <span className="font-weight-bold mr-2">Province/State:</span>
                <span className="text-muted">{this.props.data.fieldData.bAdresse.State}</span>
              </div>

              <div className="d-flex align-items-center justify-content-between mb-2">
                <span className="font-weight-bold mr-2">Pays:</span>
                <span className="text-muted">{this.props.data.fieldData.bAdresse.Country}</span>
              </div>

              <div className="d-flex align-items-center justify-content-between mb-2">
                <span className="font-weight-bold mr-2">Code Postal:</span>
                <span className="text-muted">{this.props.data.fieldData.bAdresse.Zip}</span>
              </div>

            </Col>

            <Col xs={12} xl={4}>

              <div className="d-flex align-items-center justify-content-between mb-2">
                <span className="font-weight-bold mr-2">Langue:</span>
                <span className="text-muted">{this.props.data.fieldData.Langue}</span>
              </div>

              <div className="d-flex align-items-center justify-content-between mb-2">
                <span className="font-weight-bold mr-2">Telephone 1:</span>
                <span className="text-muted">{this.props.data.fieldData.bTel1}</span>
              </div>

              <div className="d-flex align-items-center justify-content-between mb-2">
                <span className="font-weight-bold mr-2">Telephone 2:</span>
                <span className="text-muted">{this.props.data.fieldData.bTel2}</span>
              </div>

              <div className="d-flex align-items-center justify-content-between mb-2">
                <span className="font-weight-bold mr-2">Telephone 3:</span>
                <span className="text-muted">{this.props.data.fieldData.bTel3}</span>
              </div>

              <div className="d-flex align-items-center justify-content-between mb-2">
                <span className="font-weight-bold mr-2">Telephone 4:</span>
                <span className="text-muted">{this.props.data.fieldData.bTel4}</span>
              </div>

            </Col>

          </Row>

        </CardBody>
      </div>
    </Card>
  }
}

export default FM1Card;