/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import PerfectScrollbar from "react-perfect-scrollbar";
import { toAbsoluteUrl } from "../../../../../helpers";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

export function SearchResult({ data }) {
  if (!data) {
    return null;
  }

  if (data.company.length === 0 && data.person.length === 0) {
    return (
      <div
        style={{ maxHeight: "325px", overflow: "hidden" }}
        className="quick-search-wrapper scroll ps ps--active-y"
      >
        <div className="quick-search-result">
          <div className="text-muted text-center">No result found</div>
        </div>
      </div>
    );
  }

  return (
    <div
      style={{ maxHeight: "325px", overflow: "hidden" }}
      className="quick-search-wrapper scroll ps ps--active-y"
    >
      <PerfectScrollbar
        options={perfectScrollbarOptions}
        className="scroll"
        style={{ maxHeight: "325px", position: "relative" }}
      >
        <div className="quick-search-result">
          <div className="row">
            <div className="col-xs-12 col-sm-6">
              {/* begin: Section */}
              <div className="font-size-sm text-primary font-weight-bolder text-uppercase mb-2">
                Companies
              </div>
              <div className="mb-10">

                {data.company.slice(0, 20).map((company, i) => {
                  // Return the element. Also pass key
                  return (<>
                    <div key={company.id} className="d-flex align-items-center flex-grow-1 mb-2">
                      <div className="symbol symbol-45 bg-transparent flex-shrink-0">
                        <img src={company.logo}></img>
                      </div>
                      <div className="d-flex flex-column ml-3 mt-2 mb-2">
                        <a
                          href="#"
                          className="font-weight-bold text-dark text-hover-primary"
                        >
                          {company.name}
                        </a>
                        <span className="font-size-sm font-weight-bold text-muted">
                          {company.domains.join(',')}
                        </span>
                      </div>
                    </div>
                    </>)
                })}

              </div>
              {/* end: Section */}
            </div>

            <div className="col-xs-12 col-sm-6">
              {/* begin: Section */}
              <div className="font-size-sm text-primary font-weight-bolder text-uppercase mb-2">
                Persons
              </div>
              <div className="mb-10">

                {data.person.slice(0, 20).map((person, i) => {
                  // Return the element. Also pass key
                  return (<>
                    <div key={person.id} className="d-flex align-items-center flex-grow-1 mb-2">
                      <div className="symbol symbol-45 bg-transparent flex-shrink-0">
                        <SVG src={toAbsoluteUrl("/media/svg/files/doc.svg")}></SVG>
                      </div>
                      <div className="d-flex flex-column ml-3 mt-2 mb-2">
                        <a
                          href="#"
                          className="font-weight-bold text-dark text-hover-primary"
                        >
                          {person.name}
                        </a>
                        <span className="font-size-sm font-weight-bold text-muted">
                        by Grog John
                      </span>
                      </div>
                    </div>
                  </>)
                })}


              </div>
              {/* end: Section */}
            </div>

          </div>


        </div>
      </PerfectScrollbar>
    </div>
  );
}
