import React, { Component } from 'react';
import {connect} from "react-redux";
import actions from "../../_redux/actions";
import {apiService} from "../../services/api"
import {Card, CardBody, CardHeader, CardHeaderToolbar, Pagination} from "../../partials/controls";
import paginationFactory, {PaginationProvider} from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import {NoRecordsFoundMessage, PleaseWaitMessage} from "../../../helpers";
import {LogOffButton} from "../../../auth/Auth0"


class ContactModQueue extends Component {

  constructor() {
    super();

    this.state = {
      loading: true,
      pagination: {
        custom: true,
        totalSize: 0,
        sizePerPage: 25,
        page: 1
      },
      data: [],
      selected_id: null,
      search: ''
    }
  }

  async componentDidMount() {
    const {response, error} = await apiService.contact_mod.getAll()

    this.setState({
      ...this.state,
      loading: false,
      data: response.data.data,
      pagination: {
        ...this.state.pagination,
        totalSize: response.data.data.length
      },
    })

  }

  async onTableChange(type, newState) {
    if(type === 'pagination') {
      this.setState({
        ...this.state,
        pagination: {
          ...this.state.pagination,
          page: newState.page
        },
      })
      window.scrollTo(0, 0)
    }
  }

  removeLineInTable(row) {
    let data = this.state.data.filter(item => item !== row)
    this.setState({
      ...this.state,
      data: data
    })
  }

  async acceptChangeSet(row) {
    const {response, error} = await apiService.contact_mod.accept(row.id)

    if(response.status >= 200 && response.status < 300) {
      this.removeLineInTable(row)
    } else {
      alert('Une erreur à eu lieu! Veuillez ré-essayer plus tard.')
    }
  }

  async refuseChangeSet(row) {
    const {response, error} = await apiService.contact_mod.refuse(row.id)

    if(response.status >= 200 && response.status < 300) {
      this.removeLineInTable(row)
    } else {
      alert('Une erreur à eu lieu! Veuillez ré-essayer plus tard.')
    }
  }

  setQuickSearch(e) {
    this.setState({
      ...this.state,
      search: e.target.value,
      pagination: {
        ...this.state.pagination,
        page: 1
      }
    })
  }

  render() {

    if(this.state.loading) {
      return null
    }

    const ContactModColumns = [
      {
        dataField: "contact_id",
        text: "Contact Id",
        formatter: (cell, row) => {
          return <a target='_blank' href={'https://dansedanse.lightning.force.com/lightning/r/Contact/' + row.contact_id + '/view'}>
            <i className='fab fa-salesforce text-primary'/> {row.contact_name !== undefined ? row.contact_name : row.contact_id}
          </a>
        }
      },
      {
        dataField: "created_date",
        text: "Date du Changement",
        formatter: (cell, row) => {
          return row.created_date.split('T')[0]
        }
      },
      {
        dataField: "change_set.field_name",
        text: "Champ",
      },
      {
        dataField: "change_set.before",
        text: "Ancienne Information",
        formatter: (cell, row) => {
          return <div><pre>{JSON.stringify(row.change_set.before, null, 2) }</pre></div>
        }
      },
      {
        dataField: "change_set.after",
        text: "Nouvelle Information",
        formatter: (cell, row) => {
          return <div><pre>{JSON.stringify(row.change_set.after, null, 2) }</pre></div>
        }
      },
      {
        dataField: "action",
        text: "Actions",
        formatter: (cell, row) => {
          return <>
            <button className="btn btn-outline-primary btn-sm" title="Accept Change" onClick={this.acceptChangeSet.bind(this, row)}>
              <i className="far fa-check-circle" /> Accepter
            </button>
            <button className="btn btn-outline-danger btn-sm ml-2" title="Refuse Change" onClick={this.refuseChangeSet.bind(this, row)}>
              <i className="fas fa-window-close" /> Refuser
            </button>
          </>
        }
      }
    ];

    const NeedsReviewWhere = this.state.pagination.page === 1 ? 0 : ((this.state.pagination.page * this.state.pagination.sizePerPage) - this.state.pagination.sizePerPage)
    let FilteredValues = this.state.data.slice(
      NeedsReviewWhere,
      NeedsReviewWhere + this.state.pagination.sizePerPage
    );

    if(this.state.search.length > 3) {
      FilteredValues = this.state.data.filter(contact_mod => contact_mod.contact_name.includes(this.state.search));
    }

    return <>
      <div id="kt_subheader" className={`subheader py-2 py-lg-4 subheader-solid`}>
        <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
          {/* Info */}
          <div className="d-flex align-items-center flex-wrap mr-2">
            <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Salesforce</h5>
            <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-4 bg-gray-200" />
            <span className="text-muted font-weight-bold mr-4">Modifications des Contacts</span>
          </div>
          <div className="d-flex align-items-center">
            <LogOffButton />
          </div>
        </div>
      </div>
      <div className="d-flex flex-column-fluid">
        <div className="container-fluid">

          <Card className="card card-custom">
            <CardHeader>
              <div className="card-title">
                <h3 className="card-label">
                  Liste des Modifications Contacts
                </h3>
              </div>
              <CardHeaderToolbar>
                <input
                  style={{width: '500px'}}
                  className="form-control quick-search"
                  placeholder="Filter par Nom du Contact..."
                  onChange={this.setQuickSearch.bind(this)}
                />
              </CardHeaderToolbar>
            </CardHeader>
            <div>
              <CardBody>

                <PaginationProvider pagination={paginationFactory(this.state.pagination)}>
                  {({ paginationProps, paginationTableProps }) => {
                    return (
                      <Pagination
                        isLoading={this.state.loading}
                        paginationProps={paginationProps}
                      >
                        <BootstrapTable
                          wrapperClasses="table-responsive"
                          bordered={false}
                          classes="table table-head-custom table-vertical-center"
                          bootstrap4
                          remote
                          keyField="id"
                          data={FilteredValues === null ? [] : FilteredValues}
                          columns={ContactModColumns}
                          onTableChange={this.onTableChange.bind(this)}
                          {...paginationTableProps}
                        >
                          <PleaseWaitMessage entities={FilteredValues} />
                          <NoRecordsFoundMessage entities={FilteredValues} />
                        </BootstrapTable>
                      </Pagination>
                    );
                  }}
                </PaginationProvider>

              </CardBody>
            </div>
          </Card>

        </div>
      </div>
    </>
  }
}

export default connect(
  state => ({
    ...state.app,
  }),
  actions
)(ContactModQueue);