import {all} from "redux-saga/effects";
import {combineReducers} from "redux";
import { connectRouter } from 'connected-react-router'


import * as auth from "../auth/_redux/authRedux";
import { appReducer }  from "../app/_redux/reducers";

import appSagas from "../app/_redux/sagas";

export const rootReducer = (history) => combineReducers({
  router: connectRouter(history),
  auth: auth.reducer,
  app: appReducer,
})

export function* rootSaga() {
  yield all([auth.rootSaga(), appSagas()]);
}
