const devConfiguration = {
  environment: 'local',
  api_endpoint: 'http://127.0.0.1:8089',
  auth0: {
    domain: 'dansedanse.us.auth0.com',
    audience: 'https://api.dansedanse.me',
    clientId: 'qQt7DhYvJesgD7wAuCRyjVrUf6a4TkMN',
    redirectUri: 'http://localhost:3000/callback',
    returnTo: 'http://localhost:3000/callback'
  }
}

const prodConfiguration = {
  environment: 'production',
  api_endpoint: 'https://api.dansedanse.me',
  auth0: {
    domain: 'dansedanse.us.auth0.com',
    audience: 'https://api.dansedanse.me',
    clientId: 'qQt7DhYvJesgD7wAuCRyjVrUf6a4TkMN',
    redirectUri: 'https://dansedanse.me/callback',
    returnTo: 'https://dansedanse.me'
  }
}

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'production'
  ? prodConfiguration
  : devConfiguration;

export default {
  ...config
};