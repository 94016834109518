import {configureStore, getDefaultMiddleware} from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import {reduxBatch} from "@manaflair/redux-batch";
// import {applyMiddleware} from "redux";
import {persistStore} from "redux-persist";
import {rootReducer, rootSaga} from "./rootReducer";
import { createBrowserHistory } from 'history'
import reduxWebsocket from '@giantmachines/redux-websocket';

const sagaMiddleware = createSagaMiddleware();

// Create the middleware instance.
const reduxWebsocketMiddleware = reduxWebsocket();

const middleware = [
  ...getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
    thunk: true
  }),
  reduxWebsocketMiddleware,
  sagaMiddleware
];

export const history = createBrowserHistory()

const store = configureStore({
  reducer: rootReducer(history),
  middleware,
  devTools: process.env.NODE_ENV !== "production",
  enhancers: [reduxBatch]
});

/**
 * @see https://github.com/rt2zz/redux-persist#persiststorestore-config-callback
 * @see https://github.com/rt2zz/redux-persist#persistor-object
 */
export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export default store;
