import React, {Component} from "react";
import {toast} from "react-toastify";
import KTReactControlledOffCanvas from "../../../assets/js/layout/extended/react-controlled";


class OffCanvasForm extends Component {

  oc_name = undefined;
  oc_css_id = undefined;
  defaultValues = undefined;

  componentDidMount() {
    KTReactControlledOffCanvas.init(this.oc_css_id)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.off_canvas[this.oc_name]['show'] !== this.props.off_canvas[this.oc_name]['show']) {
      this.toggle()
    }
  }

  toggle() {

    const QuickUserOffCanvas = document.getElementById(this.oc_css_id);

    if(this.props.off_canvas[this.oc_name]['show'] === true) {
      QuickUserOffCanvas.classList.add('offcanvas-on');

      // Adding Darker Background Overlay
      const newEl = document.createElement("div");
      newEl.id = 'offcanvas-overlay'
      newEl.classList.add('offcanvas-overlay');
      QuickUserOffCanvas.after(newEl)

      this.setState({
        values: {
          ...this.defaultValues,
          ...this.props.off_canvas[this.oc_name]['data']
        }
      })

    } else {
      QuickUserOffCanvas.classList.remove('offcanvas-on');

      const OffCanvasOverlay = document.getElementById("offcanvas-overlay");
      if (OffCanvasOverlay) {
        OffCanvasOverlay.remove()
      }

      this.setState({
        values: {
          ...this.defaultValues
        },
        errors: {

        }
      })

    }
  }

  onSmartChange(field_name, values) {
    this.setState({
      ...this.state,
      values: {
        ...this.state.values,
        [field_name]: values
      },
      errors: {
        ...this.state.errors,
        [field_name]: undefined
      }
    })
  }

  onChange(e) {
    this.setState({
      ...this.state,
      values: {
        ...this.state.values,
        [e.target.name]: e.target.value
      },
      errors: {
        ...this.state.errors,
        [e.target.name]: undefined
      }
    })
  }

  onDateChange(field_name, e) {
    this.setState({
      ...this.state,
      values: {
        ...this.state.values,
        [field_name]: e.date
      },
      errors: {
        ...this.state.errors,
        [field_name]: undefined
      }
    })
  }

  async formSubmission(lead_id, api_function) {
    let formValues = {}

    this.setState({
      ...this.state,
      loading: true
    })

    for (const [key, value] of Object.entries(this.state.values)) {
      if(value !== this.defaultValues[key]) {
        formValues[key] = value
      }
    }

    const {response, error} = await api_function(
      lead_id,
      formValues
    )

    if(error) {
      this.handleErrors(error.response.data.errors.json)
    } else {
      this.props.toggleOffCanvas(this.oc_name)
      toast.success("Object was successfully created.")
    }

  }

  handleErrors(errors) {
    let newState = JSON.parse(JSON.stringify(this.state))

    newState.loading = false

    for (const [key, value] of Object.entries(errors)) {
      if(value !== this.defaultValues[key]) {
        newState.errors[key] = value
      }
    }

    this.setState(newState)

  }

  displayFieldError(field_name) {
    return Array.isArray(this.state.errors[field_name]) ? this.state.errors[field_name].map((fieldError, i) => {
      return <div key={i} className="invalid-feedback">{fieldError}</div>
    }): null
  }

}

export default OffCanvasForm