/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { Route, Switch } from 'react-router'
import Error404 from "../errors/404";
import ContactModQueue from "./modules/ModificationQueue/ContactModQueue";
import ClientImport from "./modules/Forms/ClientImport";
import ParticipationImport from "./modules/Forms/ParticipationImport";
import EmailImport from "./modules/Forms/EmailImport";
import PDAImport from "./modules/Forms/PDAImport";
import FMV1Search from "./modules/Filemaker/FMV1Search";
import FMV2Search from "./modules/Filemaker/FMV2Search";
import SFContactSearch from "./modules/Salesforce/SFContactSearch";
import QBClassRefresh from "./modules/Forms/QBClassRefresh";


export function AppRoutes() {
    return (
        <Switch>
          <Route exact path="/" component={ContactModQueue}/>
          <Route exact path="/qb/create_classes" component={QBClassRefresh}/>

          <Route exact path="/forms/pda/import" component={PDAImport}/>
          <Route exact path="/forms/pda/client_import" component={ClientImport}/>
          <Route exact path="/forms/pda/email_import" component={EmailImport}/>
          <Route exact path="/forms/other/participant_import" component={ParticipationImport}/>

          <Route exact path="/sf/contact/:sfContactId" component={SFContactSearch}/>

          <Route exact path="/data/fm/v1" component={FMV1Search}/>
          <Route exact path="/data/fm/v1/:fmv1Id" component={FMV1Search}/>
          <Route exact path="/data/fm/v2" component={FMV2Search}/>
          <Route exact path="/data/fm/v2/:fmv2Id" component={FMV2Search}/>
          <Route exact path="/data/sf/contact" component={SFContactSearch}/>

          <Route exact path="/contact/:contactId" component={Error404}/>

          <Route component={Error404}/>
        </Switch>
    );
}
