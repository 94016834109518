import React, {Component} from "react";
import clsx from "clsx";
import { Dropdown } from "react-bootstrap";
import SVG from "react-inlinesvg";
import {connect} from "react-redux";
import { SearchResult } from "./SearchResult";
import { toAbsoluteUrl } from "../../../../../helpers";
import { DropdownTopbarItemToggler } from "../../../../partials/dropdowns";
import actions from "../../../../_redux/actions"


class SearchDropdown extends Component {

  constructor(props) {
    super(props);
    this.state = {
      value: "",
      showResults: false
    };
    this.timeoutId = undefined;
    this.handleChange = this.handleSearchChange.bind(this);
    this.clearTimeout = this.clearTimeout.bind(this);
    this.clear = this.clear.bind(this);
  }

  handleSearchChange(event) {
    this.setState({value: event.target.value});

    if (event.target.value.length > 2) {
      this.clearTimeout()

      this.timeoutId = setTimeout(() => {
        this.props.requestSearchResults(this.state.value);
      }, 500);

    } else {
      this.showResults = false
    }
  }

  clear() {
    this.setState({value: "", showResults: false});
    this.timeoutId = undefined
  }

  clearTimeout() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = undefined
    }
  }

  render() {

    var divStyle = {
      paddingLeft: '10px',
      paddingRight: '10px'
    };

    return (
      <>
        <Dropdown
          drop="down"
          show={this.props.results && this.state.value !== ""}
          onToggle={() => {
            this.clear.bind(this);
          }}
          id="kt_quick_search_toggle"
        >
          <Dropdown.Toggle as={DropdownTopbarItemToggler}>
            <div
              id="kt_quick_search_dropdown"
              className={clsx("quick-search quick-search-dropdown w-800px", {
                "quick-search-has-result": this.props.results && this.props.results.length,
              })}
            >
              <form className="quick-search-form">
                <div className="input-group rounded bg-light" style={divStyle}>
                  <div className={`input-group-prepend`}>
                  <span className="input-group-text">
                    <span className="svg-icon svg-icon-lg">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/General/Search.svg"
                        )}
                      />
                    </span>
                  </span>
                  </div>
                  <input
                    type="text"
                    autoFocus={false}
                    placeholder="Search..."
                    value={this.state.value}
                    onChange={this.handleChange}
                    className="form-control"
                  />

                  <div
                    className={`input-group-append ${
                      this.props.loading ? "spinner spinner-sm spinner-primary" : ""
                    }")}`}
                  >
                  <span className="input-group-text">
                    <i
                      style={{
                        display:
                          this.state.value && this.state.value.length > 0 && this.props.loading !== true
                            ? "flex"
                            : "none",
                      }}
                      onClick={this.clear.bind(this)}
                      className="quick-search-close ki ki-close icon-sm text-muted"
                    />
                  </span>
                  </div>
                </div>
              </form>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-left dropdown-menu-anim-up dropdown-menu-lg w-800px">
            <div
              id="kt_quick_search_dropdown"
              className={clsx("quick-search quick-search-dropdown", {
                "quick-search-has-result": this.props.results && this.state.value,
              })}
            >
              <SearchResult data={this.props.results} />
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  }
}

export default connect(
  state => ({
    ...state.app.search,
  }),
  actions
)(SearchDropdown);
